import React, { Component } from "react";

class Footer extends Component {

    render() {
        return (
            <div className="footer">
                <div className="logo-ctr-1">
                    {/* <span> */}
                        <img src={require("../assets/img/footer/Logo_CODM.png")} alt="logo-codm" className="logo-codm" />
                    {/* </span> */}
                    {/* <a href="https://www.facebook.com/codmID" target="_blank" rel="noopener noreferrer">
                        <img src={require("../assets/img/footer/Logo_Facebook.png")} alt="facebook" className="social fb" />
                    </a>
                    <a href="https://www.instagram.com/garenacodmid/?hl=id" target="_blank" rel="noopener noreferrer">
                        <img src={require("../assets/img/footer/Logo_IG.png")} alt="instagram" className="social ig" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCj3YUPBe-eKUCjBoE-QY2jA" target="_blank" rel="noopener noreferrer">
                        <img src={require("../assets/img/footer/Logo_YT.png")} alt="youtube" className="social yt" />
                    </a> */}
                </div>
                <div className="logo-ctr-2">
                    <img src={require("../assets/img/footer/Logo_Garena.png")} alt="garena" />
                    <img src={require("../assets/img/footer/Logo_Act.png")} alt="activision" />
                    <img src={require("../assets/img/footer/Logo_Timi.png")} alt="timi" />
                </div>
                <div className="copyright">Copyright © Garena Online. Copyright © Tencent. All rights reserved. © 2019 Activision Publishing, Inc. ACTIVISION and CALL OF DUTY are trademarks of Activision Publishing, Inc.Trademarks belong to their respective owners. All rights reserved.</div>
            </div>
        );
    }

}

export default Footer;