import React, { Component } from 'react';

// Partials
import FormNav from '../partial/FormNav';
import StepOneHook from '../partial/StepOneHook';
import StepTwoHook from '../partial/StepTwoHook';

class FormOnline extends Component {
  renderCurrentStep = () => {
    switch (this.props.main.step) {
      case 1:
        return <StepOneHook {...this.props} />;
      case 2:
        return <StepTwoHook {...this.props} />;
      default:
        return <StepOneHook {...this.props} />;
    }
  };

  render() {
    const { lang_json } = this.props.main;

    return (
      <main
        className="form form-last"
        style={{
          backgroundImage: `url(${lang_json.img_background})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
          backgroundSize: '100% auto',
        }}>
        <FormNav {...this.props} />
        <div className="content">
          <div className="form__container container">
            <div className="row justify-content-center">
              <div className="form-major-series col-12 col-sm-10 col-md-8">
                <div className="content">
                  <div className="major-series__title">
                    <h2>{lang_json.text_title}</h2>
                    <div className="line">
                      <div className="line-dark"></div>
                      <div className="line-light"></div>
                      <div className="line-dark"></div>
                    </div>
                  </div>

                  <div className="major-series__desc">
                    <p>{lang_json.text_description}</p>
                  </div>

                  <div
                    className="major-series__accordion accordion"
                    id="accordionExample">
                    <div className="card">
                      <div
                        className="card-header"
                        id="headingOne"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne">
                        <p>{lang_json.prize}</p>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample">
                        <div
                          className="card-body"
                          dangerouslySetInnerHTML={{
                            __html: lang_json.text_hadiah,
                          }}></div>
                      </div>
                    </div>

                    <div className="card">
                      <div
                        className="card-header"
                        id="headingTwo"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo">
                        <p>{lang_json.terms_and_conditions_registration}</p>
                      </div>

                      <div
                        id="collapseTwo"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample">
                        <div
                          className="card-body"
                          dangerouslySetInnerHTML={{
                            __html: lang_json.text_informasi_ketentuan,
                          }}></div>
                      </div>
                    </div>

                    <div className="card">
                      <div
                        className="card-header"
                        id="headingThree"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree">
                        <p>{lang_json.regulation}</p>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample">
                        <div
                          className="card-body"
                          dangerouslySetInnerHTML={{
                            __html: lang_json.text_regulasi_pertandingan,
                          }}></div>
                      </div>
                    </div>
                  </div>

                  <div className="major-series__step">
                    <div
                      className={`step step-1 ${
                        this.props.main.step === 1 ? `active` : `passed`
                      }`}>
                      <p>
                        {`${lang_json.step_1} `}
                        {this.props.main.step > 1 ? (
                          <img
                            src="https://cdngarenanow-a.akamaihd.net/webid/CODM/minorseries/Centang.png"
                            alt="passed"
                          />
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                    <div
                      className={`step step-2 ${
                        this.props.main.step === 2 ? `active` : ``
                      }`}>
                      <p>{lang_json.step_2}</p>
                    </div>
                  </div>

                  <div className="major-series__form">
                    {this.renderCurrentStep()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default FormOnline;
