import axios from 'axios';
import { getUrlParam, removeParamUrl } from '../../helper';
import { toggle_modal } from './modal';

const ls = window.localStorage;

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
  transformRequest: [
    (data, headers) => {
      delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
      return data;
    },
  ],
});

export const get_login = (data) => ({
  type: 'GET_LOGIN',
  data,
});

export const translate = (data) => ({
  type: 'GET_TRANSLATE',
  data,
});

export const toggle_loader = (data) => ({
  type: 'TOGGLE_LOADER',
  data: data,
});

export const change_step = (data) => ({
  type: 'CHANGE_STEP',
  data,
});

export const update_form = (group, name, value, memberIndex) => ({
  type: 'UPDATE_FORM',
  group,
  memberIndex,
  name,
  value,
});

export const get_setting = (data) => ({
  type: 'GET_SETTING',
  data,
});

export const get_form = (data) => ({
  type: 'GET_FORM',
  data,
});

export const error_msg = (data) => ({
  type: 'ERROR_MSG',
  data,
});

export const setToken = () => {
  return (dispatch) => {
    const token = getUrlParam('token');
    if (token) {
      ls.setItem('token', token);
      removeParamUrl();
      window.location.href = window.location.origin;
    }
  };
};

export const get_translate = (lang) => {
  return (dispatch) => {
    cdn
      .get(`${lang}.json?${Math.random() * 10000}`)
      .then((resp) => {
        dispatch(translate(resp.data));
      })
      .catch((err) => {});
  };
};

export const loginOnline = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}oauth/check_login/`)
      .then((resp) => {
        dispatch(get_login(resp.data));
        dispatch(getFormSetting());
        dispatch(getFormOnline());
      })
      .catch((err) => {
        const msg = err.response ? err.response.data.msg : '';
        if (msg === 'event_finished') {
          dispatch(toggle_modal(true, 'offline2'));
        } else if (msg === 'maintenance') {
          dispatch(toggle_modal(true, 'offline1'));
        } else {
          window.location.href = `${process.env.REACT_APP_API_ENDPOINT}oauth/login/`;
        }
      });
  };
};

export const getFormOnline = () => {
  return (dispatch, getState) => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}api/form/`)
      .then((resp) => {
        if (resp.data.registered !== false) {
          if (resp.data.isLeader === true) {
            dispatch(get_form(resp.data));
          }

          if (window.location.pathname === '/') {
            window.location = '/registered';
          } else if (
            window.location.pathname === '/online-reg' &&
            !window.localStorage.editForm
          ) {
            window.location = '/registered';
          }
        } else {
          if (window.location.pathname !== '/online-reg') {
            window.location = '/online-reg';
          }

          dispatch(
            update_form(
              'member',
              'member_nickname',
              getState().login.codm_nickname,
              0
            )
          );
          dispatch(
            update_form(
              'member',
              'member_t_open_id',
              getState().login.t_open_id,
              0
            )
          );
        }
      })
      .catch((err) => {});
  };
};

export const putFormOnline = () => {
  return (dispatch, getState) => {
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}api/form/`,
        getState().main.get
      )
      .then((resp) => {
        window.location = '/registered';
      })
      .catch((err) => {
        if (err.response.data.message.key.page === 1) dispatch(change_step(1))
        dispatch(error_msg(err.response.data.message));
      });
  };
};

export const postFormOnline = () => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}api/form/`,
        getState().main.post
      )
      .then((resp) => {
        window.location = '/registered';
      })
      .catch((err) => {
        if (err.response.data.message.key.page === 1) dispatch(change_step(1))
        dispatch(error_msg(err.response.data.message));
      });
  };
};

export const getFormSetting = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}api/setting/`)
      .then((resp) => {
        dispatch(get_setting(resp.data))
      })
      .catch((err) => {
        dispatch(error_msg(err.response.data.message));
      });
  }
}
