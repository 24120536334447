const tabs_state = {
    tabs_name: "rules"
};

const tabs = (state = tabs_state, action) => {
    switch (action.type) {
        case "TOGGLE_TABS":
            return { ...state, tabs_name: action.name };
        default:
            return state;
    }
};

export default tabs;