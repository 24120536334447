import React, { Component } from 'react';

// Partials
import FormNav from '../partial/FormNav';

const ls = window.localStorage;

class Finish extends Component {
  componentWillMount() {
    if (this.props.main.get.registered === false) {
      window.location = '/';
    } else {
      window.localStorage.editForm = false;
    }
  }

  editBtn = () => {
    window.localStorage.setItem('editForm', true);

    if (
      ls.getItem('qualType') === 'offline1' ||
      ls.getItem('qualType') === 'offline2'
    ) {
      window.location = '/offline-reg';
    } else {
      window.location = '/online-reg';
    }
  };

  render() {
    const { lang_json } = this.props.main;

    return (
      <main
        className="finish bg-finish__last"
        style={{
          backgroundImage: `url(${lang_json.img_background})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
          backgroundSize: '100% auto',
        }}>
        <FormNav {...this.props} />
        <div className="content">
          <div className="form__container container">
            <div className="row justify-content-center">
              <div className="form-major-series col-12 col-sm-10 col-md-8">
                <div className="content">
                  <div className="major-series__title">
                    <h2>{lang_json.text_title}</h2>
                    <div className="line">
                      <div className="line-dark"></div>
                      <div className="line-light"></div>
                      <div className="line-dark"></div>
                    </div>

                    <div className="desc-title">
                      <p>{lang_json.text_finish_desc}</p>
                    </div>

                    <div className="desc-content">
                      {this.props.main.get.isLeader ? (
                        <p>{lang_json.text_finish_leader}</p>
                      ) : (
                        <p>{lang_json.text_finish_member}</p>
                      )}
                    </div>

                    {this.props.main.get.isLeader ? (
                      <button
                        className="button edit"
                        onClick={() => this.editBtn()}></button>
                    ) : (
                      ''
                    )}

                    <div className="line">
                      <div className="line-dark"></div>
                      <div className="line-light"></div>
                      <div className="line-dark"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Finish;
