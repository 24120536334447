const modal_state = {
    event_closed: false,
    reg_type: false
};

const modal = (state = modal_state, action) => {
    switch (action.type) {
        case "TOGGLE_MODAL":
            return { ...state, event_closed: action.toggle, reg_type: action.reg_type };
        default:
            return state;
    }
};

export default modal;