import { combineReducers } from "redux";
import main from "./main";
import modal from "./modal";
import tabs from "./nav-tabs";

export default combineReducers({
    main,
    modal,
    tabs
});
