import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function App(props) {
    const { lang_json, setting, error } = props.main
    const { postFormOnline, putFormOnline } = props.actionsMain
    const { register, handleSubmit, errors, setError } = useForm();

    const onSubmit = () => {
        props.main.get.id === null ? postFormOnline() : putFormOnline()
    }

    const handleChange = (event, group, index) => {
        let { name, value } = event.target;
        name = name.slice(0, -2)
        props.actionsMain.update_form(group, name, value, index);
    };

    const handlePrev = (event) => {
        props.actionsMain.change_step(1);
        event.preventDefault();
    };

    useEffect(() => {
        if (error.key.name_field !== '' && error.key.page === 2) {
            setError(error.key.name_field, {type: "manual", message: error.msg, shouldFocus: true})
        }
    }, [error, setError]) 

    const anggota = () => {
        const arr = [true, true, true, true, setting.is_need_reserve]
        let form = arr.map((required, data) =>
            <div key={data} className="anggota-form">
                <div className="field-title">
                    <p>{lang_json[`member_${data + 2}`]}</p>
                </div>
                <span className="field-desc">{lang_json.member_desc}</span>
                <div className="form-content member-name">
                    <p className="title-form">{lang_json.full_name} <span>{required && '*'}</span></p>
                    <p className="desc-form">{lang_json.full_name_desc}</p>
                    <input
                        type="text"
                        name={`member_name_${data + 2}`}
                        className="input input__member-name first"
                        defaultValue={
                            props.main.get.id !== null
                                ? props.main.get.member[data + 1].member_name
                                : props.main.post.member[data].member_name
                        }
                        onChange={(event) => handleChange(event, 'member', data + 1)}
                        ref={register({ required: {value: required, message: "Required"}, pattern: { value: /^[a-zA-Z ]*$/, message: "Only alphabets" } })}
                    />
                    {errors[`member_name_${data + 2}`] && <span className="error_message">{errors[`member_name_${data + 2}`].message}</span>}
                </div>

                { setting.is_need_identity &&
                    <div className="form-content member-id">
                        <p className="title-form">{lang_json.id_number} <span>{required && '*'}</span></p>
                        <p className="desc-form">{lang_json.id_number_desc}</p>
                        <input
                            type="number"
                            name={`idCard_number_${data + 2}`}
                            className="input input__member-id first"
                            defaultValue={
                                props.main.get.id !== null
                                    ? props.main.get.member[data + 1].idCard_number
                                    : props.main.post.member[data].idCard_number
                            }
                            onChange={(event) => handleChange(event, 'member', data + 1)}
                            ref={register({ required: {value: required, message: "Required"}, minLength: { value: 3, message: "Minimum 3 digits" }, maxLength: { value: 30, message: "Maximum 30 digits" }, pattern: { value: /^[0-9]*$/, message: "Only numeric" } })}
                        />
                        {errors[`idCard_number_${data + 2}`] && <span className="error_message">{errors[`idCard_number_${data + 2}`].message}</span>}
                    </div>
                }
                <div className="form-content member-nickname">
                    <p className="title-form">{lang_json.nickname} <span>{required && '*'}</span></p>
                    <p className="desc-form">{lang_json.nickname_desc}</p>
                    <input
                        type="text"
                        name={`member_nickname_${data + 2}`}
                        className="input input__member-nickname first"
                        defaultValue={
                            props.main.get.id !== null
                              ? (props.main.get.member[data + 1].member_nickname && 
                                decodeURIComponent(props.main.get.member[data + 1].member_nickname))
                              : (props.main.post.member[data].member_nickname && 
                                decodeURIComponent(props.main.post.member[data].member_nickname))
                        }
                        onChange={(event) => handleChange(event, 'member', data + 1)}
                        ref={register({ required: {value: required, message: "Required"} })}
                    />
                    {errors[`member_nickname_${data + 2}`] && <span className="error_message">{errors[`member_nickname_${data + 2}`].message}</span>}
                </div>

                <div className="form-content member-OpenId">
                    <p className="title-form">{lang_json.open_id} <span>{required && '*'}</span></p>
                    <p className="desc-form">{lang_json.open_id_desc}</p>
                    <img
                        className="openid-img"
                        src="https://cdngarenanow-a.akamaihd.net/webid/CODM/minorseries/Open_ID.jpg"
                        alt=""
                    />
                    <input
                        type="number"
                        name={`member_t_open_id_${data + 2}`}
                        className="input input__member-openid first"
                        defaultValue={
                            props.main.get.id !== null
                                ? props.main.get.member[data + 1].member_t_open_id
                                : props.main.post.member[data].member_t_open_id
                        }
                        onChange={(event) => handleChange(event, 'member', data + 1)}
                        ref={register({ required: {value: required, message: "Required"} })}
                    />
                    {errors[`member_t_open_id_${data + 2}`] && <span className="error_message">{errors[`member_t_open_id_${data + 2}`].message}</span>}
                </div>
            </div>
        )
        return form
    }

    return (
        <div className="form form-one"> 
            <form onSubmit={handleSubmit(onSubmit)}>
                {anggota()}
                {error.key.page === 0 && ( <p className="error">{error.msg}</p> )}
                <button className="button back" onClick={(event) => handlePrev(event)}></button>
                <button className="button submit" type="submit"></button>
                <p className="notes">*{lang_json.notes}</p>
            </form>
        </div>
    );
}