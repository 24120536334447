export const getUrlParam = (pa) => {
  var url = window.location.href.replace(/#+.*$/),
    params = url.substring(url.indexOf('?') + 1, url.length).split('&'),
    param = {};

  for (var i = 0; i < params.length; i++) {
    var pos = params[i].indexOf('='),
      key = params[i].substring(0, pos),
      val = params[i].substring(pos + 1);

    param[key] = val;
  }

  return typeof param[pa] === 'undefined' ? false : param[pa];
};

export const removeParamUrl = () => {
  const location = window.location;
  window.history.pushState(
    {},
    document.title,
    location.origin + location.pathname
  );
};

export const err_msg = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 500:
        return 'Please Refresh Your Browser!';
      default:
        return err.response.data.msg;
    }
  } else if (err.request) {
    return 'Please Refresh Your Browser!';
  } else {
    return 'Please Refresh Your Browser!';
  }
};

export const validateName = (name) => {
  if (name === '') {
    return false;
  } else {
    const re = /^[a-zA-Z ]*$/;
    return re.test(name);
  }
};

export const validateCardId = (number) => {
  if (number === '') {
    return false;
  } else {
    const re = /^[0-9]*$/;
    return re.test(number);
  }
};

export const validatePhone = (number) => {
  if (number === '') {
    return false;
  } else {
    const re = /^[0-9]*$/;
    return re.test(number);
  }
};

export const validateEmail = (email) => {
  const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return re.test(email);
};

export const validateDiscord = (discord) => {
  const re = /[#]/;
  return re.test(discord);
};
