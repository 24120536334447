import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

class ModalCont extends Component {
  
  renderText = () => {
    const { reg_type } = this.props.modal;
    const { lang_json } = this.props.main
    
    let text;
    if (reg_type === 'offline1') {
      text = <p>{lang_json.maintenance}</p>;
    } else if (reg_type === 'offline2') {
      text = <p>{lang_json.registration_closed}</p>;
    }

    return text;
  };

  render() {
    return (
      <Modal
        open={this.props.modal.event_closed}
        onClose={() => this.props.actionsModal.toggle_modal(false, false)}
        showCloseIcon={false}
        focusTrapped={false}
        center={true}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        classNames={{ overlay: 'overlay', modal: 'modal_event' }}>
        <div className="content">
          <div className="title">
            {this.props.main.lang_json.title_modal}
          </div>
          {this.renderText()}
          {/* <div
            className="btn-close"
            onClick={() => this.props.actionsModal.toggle_modal(false, false)}>
            TUTUP
          </div> */}
        </div>
      </Modal>
    );
  }
}

export default ModalCont;
