import React, { Component } from 'react';

const ls = window.localStorage;

class FormNav extends Component {
  logout = () => {
    // check logout based on qualification type
    if (
      ls.getItem('qualType') === 'offline1' ||
      ls.getItem('qualType') === 'offline2'
    ) {
      window.location.href =
        process.env.REACT_APP_API_ENDPOINT +
        `oauth/logout/?token=${window.localStorage.token}`;
    } else if (ls.getItem('qualType') === 'online') {
      window.location.href =
        process.env.REACT_APP_API_ENDPOINT +
        `oauth/logout/?token=${window.localStorage.token}`;
    } else {
      window.location.href =
        process.env.REACT_APP_API_ENDPOINT +
        `oauth/logout/?token=${window.localStorage.token}`;
    }

    window.localStorage.removeItem('token');
    window.localStorage.removeItem('qualType');
  };

  render() {
    return (
      <div className="form-nav">
        <span>Hi, {decodeURIComponent(this.props.main.login.codm_nickname)}</span>
        <button
          className="button logout"
          onClick={() => this.logout()}></button>
      </div>
    );
  }
}

export default FormNav;
