import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

// Component
import ViewportRestrict from 'helper/component/viewport';
import NotFound from 'helper/component/404';
import Footer from 'partial/Footer';
import ModalCont from 'partial/ModalCont';

// Pages
import FormOnline from '../pages/FormOnline';
import Finish from 'pages/Finish';
import ErrorBoundary from 'ErrorBoundary';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as modalActions from 'redux/actions/modal';
import * as tabsActions from 'redux/actions/nav-tabs';
import { Home } from '../pages/Home';

class Main extends Component {
  componentDidMount() {
    this.props.actionsMain.setToken();
    this.props.actionsMain.get_translate('ph');
    this.props.actionsMain.loginOnline();
  }

  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME}>
        <div id="Main" className="main-panel bg-first">
          <ErrorBoundary>
            <ViewportRestrict display={true} type="portrait" />
            <ModalCont {...this.props} />
            <div className="content-container">
              <Switch>
                <Route exact path="/" render={() => <Home {...this.props} />} />
                <Route
                  exact
                  path="/online-reg"
                  render={() => <FormOnline {...this.props} />}
                />
                <Route
                  exact
                  path="/registered"
                  render={() => <Finish {...this.props} />}
                />
                <Route component={NotFound} />
              </Switch>
              <Footer />
            </div>
          </ErrorBoundary>
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    main: state.main,
    modal: state.modal,
    tabs: state.tabs
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actionsMain: bindActionCreators(mainActions, dispatch),
    actionsModal: bindActionCreators(modalActions, dispatch),
    actionsTabs: bindActionCreators(tabsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
